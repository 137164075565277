import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { BadgeComponent, IconComponent } from '@sb/ui';

@Component({
  selector: 'sb-plan-card',
  standalone: true,
  imports: [CommonModule, IconComponent, BadgeComponent, TooltipModule, TranslateModule, TranslationParamsPipe],
  templateUrl: './plan-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCardComponent {
  @Input()
  public name: string;
  @Input()
  public tagline: string;

  @Input()
  public inTrial = false;
  @Input()
  public selected = false;
  @Input()
  public disabled = false;
  @Input()
  public isCurrent = false;
  @Input()
  public mostPopular = false;
  @Input()
  public isFree = false;
  @Input()
  public trialGranted = false;
  @Input()
  public displayPrice: string;
  @Input()
  public minOrderQuantity: number;

  @Input()
  public featureTitle: string;
  @Input()
  public features: string[] = [];

  @Input()
  public brandColor: 'primary' | 'green' = 'primary';
  @Input()
  public display: 'vertical' | 'horizontal' = 'vertical';

  @Output()
  public selectPlan = new EventEmitter<void>();

  @ContentChild('disabledTooltip')
  public disabledTooltip: TemplateRef<unknown>;

  @HostListener('click')
  public onClick(): void {
    if (!this.clickable) {
      return;
    }
    this.selectPlan.emit();
  }

  public get clickable(): boolean {
    return !this.disabled && !this.selected;
  }

  public get isVertical(): boolean {
    return this.display === 'vertical';
  }
}
