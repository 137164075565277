<div class="modal-body p-0">
  <div class="modal-container">
    <div
      class="modal-main p-4"
      [ngClass]="{
        'w-full min-w-full': !overQuota
      }"
    >
      <p>
        <strong>{{ 'Before you proceed' | translate }}</strong
        ><br />
        <br />
        <span
          [innerHTML]="
            'Here’s important information before you schedule your downgrade to [message].'
              | translate
              | translationParams: { message: confirmDonwngradeSubtitleMessage | bold }
          "
        ></span>
      </p>

      <ul class="my-0 px-4 children:py-2">
        <li
          [innerHTML]="
            'Your current plan will remain active until [date].'
              | translate
              | translationParams
                : {
                    date: nextBillingDate | localeDate: { year: 'numeric', month: 'long', day: 'numeric' } | bold
                  }
          "
        ></li>
        <li>
          {{
            'You can cancel this scheduled downgrade anytime before [date].'
              | translate
              | translationParams
                : {
                    date: nextBillingDate | localeDate: { year: 'numeric', month: 'long', day: 'numeric' } : 'longDate'
                  }
          }}
        </li>
        <li
          *ngIf="currentSubscription?.scheduled_subscription"
          [innerHTML]="
            'Making this change to your subscription, overrides and cancels your already scheduled downgrade.'
              | translate
          "
        ></li>
      </ul>

      <div class="form-modal__button modal-main__button-element px-0 pb-0">
        <button sb-button [color]="'secondary'" (click)="cancel.emit()">{{ 'Cancel' | translate }}</button>
        <button sb-button [color]="'primary'" [sbLadda]="isLoading" (click)="confirm.emit()">
          {{ 'Downgrade' | translate }}
        </button>
      </div>
    </div>
    <div class="modal-sidebar" *ngIf="overQuota">
      <div class="modal-sidebar__container">
        <div class="modal-sidebar__block">
          <div class="modal-sidebar__header">{{ 'You’ll have to say goodbye to' | translate }}</div>
          <ul class="mx-3 my-2.5 list-none p-0 children:py-2">
            <li *ngFor="let quota of quotaReached">
              <sb-icon [name]="'times-circle'" class="mr-2"></sb-icon>
              {{ quota.limit > 0 ? (quota.usage - quota.limit + ' ' + quota.name | lowercase) : quota.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
