<div
  class="relative flex h-full flex-col rounded-2xl"
  [ngClass]="{
    'cursor-pointer': clickable,
    'pt-8': mostPopular,
    'outline-3 outline outline-primary-100': mostPopular && selected
  }"
  [sbTooltip]="disabled ? disabledTooltip : undefined"
  [sbTooltipPlacement]="'right'"
  [sbTooltipDisabled]="!disabled"
>
  <div
    *ngIf="mostPopular"
    class="absolute inset-x-0 top-0 flex h-12 items-start justify-center gap-2 rounded-2xl rounded-b-none pt-1.5 text-white"
    [ngClass]="{
      'bg-primary-500': selected,
      'bg-word-mark-900': !selected
    }"
  >
    <sb-icon [name]="'stars'" [size]="'lg'" class="mt-0.5"></sb-icon>
    <span class="text-base font-semibold">{{ 'Most popular' | translate }}</span>
  </div>

  <div
    class="relative flex h-full gap-4 rounded-2xl border-2 border-solid bg-white p-6"
    [ngClass]="{
      'border-primary-500': selected,
      'border-grey-200': !selected,
      'hover:border-primary-500 hover:shadow-lg': clickable,
      'outline-3 outline outline-primary-100': !mostPopular && selected,
      'mt-8': !mostPopular && isVertical,
      'flex-col': isVertical,
      'flex-row-reverse': !isVertical
    }"
  >
    <div class="flex items-center gap-4">
      <sb-badge *ngIf="isCurrent" [color]="'blue'">{{ inTrial ? 'Your Trial' : 'Current plan' }}</sb-badge>
      <sb-badge *ngIf="disabled" [color]="'grey'">{{ 'Plan not available' | translate }}</sb-badge>
      <sb-badge *ngIf="!isFree && !trialGranted" [color]="'grey'">{{ 'Free 14-day trial' | translate }}</sb-badge>

      <div
        class="ml-auto h-5 w-5 shrink-0 rounded-full border-solid"
        [ngClass]="{
          'border-grey-400 border-2': !selected,
          'border-primary-500 border-6': selected,
          'opacity-50': disabled
        }"
      ></div>
    </div>

    <div
      class="flex grow flex-col items-start overflow-hidden"
      [ngClass]="{
        'gap-4': isVertical,
        'gap-2': !isVertical
      }"
    >
      <div
        class="max-w-full"
        [ngClass]="{
          'opacity-50': disabled
        }"
      >
        <span
          class="block font-bold text-word-mark-800"
          [ngClass]="{
            'text-3xl': isVertical,
            'text-2xl': !isVertical
          }"
          >{{ name }}</span
        >
        <span *ngIf="tagline && isVertical" class="block text-base font-normal text-word-mark-600">{{ tagline }}</span>
      </div>

      <div
        class="text-sm font-medium text-word-mark-400"
        [ngClass]="{
          'opacity-50': disabled,
          'flex gap-1 flex-col': isVertical
        }"
      >
        <span
          class=""
          [ngClass]="{
            'text-4xl font-bold text-word-mark-800': isVertical
          }"
          >{{ displayPrice }}
        </span>
        <span [ngClass]="{ 'mb-5': isFree }">{{
          isFree ? ('no payment details required' | translate) : ('per active employee/month' | translate)
        }}</span>
        <span *ngIf="!isFree && isVertical">{{
          'starting at [minAmount] employees' | translate | translationParams: { minAmount: minOrderQuantity }
        }}</span>
      </div>

      <ng-container *ngIf="features?.length && isVertical">
        <hr class="m-0 h-px w-full border-none bg-grey-200" />

        <div
          class="flex flex-col gap-3.5"
          [ngClass]="{
            'opacity-50': disabled
          }"
        >
          <span class="text-base font-bold text-word-mark-800">{{ featureTitle }}</span>
          <div
            *ngFor="let feature of features"
            class="flex items-center justify-start gap-2 text-base font-medium text-word-mark-600"
          >
            <sb-icon
              class="shrink-0"
              [ngClass]="{
                'text-green-500': brandColor === 'green',
                'text-primary-500': brandColor === 'primary'
              }"
              [name]="'check'"
              [size]="'sm'"
            ></sb-icon>
            {{ feature | translate }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
