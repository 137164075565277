@if (subscriptions?.length && currentSubscription) {
  <div class="flex flex-col gap-6">
    <div class="mt-6 flex flex-wrap justify-between gap-3 lg:flex-nowrap">
      <div class="flex flex-col">
        <span class="font-semibold">{{
          'Please select the plan that you’d like to continue your subscription with.' | translate
        }}</span>
        <span
          [innerHTML]="
            'Currently you have the [currentPlan] plan.'
              | translate
              | translationParams
                : {
                    currentPlan: hasEarlyAdopterPlan ? ('Early adopter' | bold) : (currentSubscription.plan.name | bold)
                  }
          "
        >
        </span>
      </div>
      <div class="flex flex-wrap gap-3 md:flex-nowrap">
        <div class="flex flex-wrap gap-3 md:flex-nowrap">
          <span class="font-semibold text-word-mark-800" [ngClass]="{ 'opacity-50': yearlyBillingToggle.disabled }">
            {{ 'Pay monthly' | translate }}
          </span>
          <sb-slide-toggle class="block" [formInputCtrl]="yearlyBillingToggle"> </sb-slide-toggle>
          <span class="font-semibold" [ngClass]="{ 'opacity-50': yearlyBillingToggle.disabled }">
            {{ 'Pay yearly' | translate }}
          </span>
        </div>
        <sb-badge [color]="'blue'" [ngClass]="{ 'opacity-50': yearlyBillingToggle.disabled }">
          {{ 'Save up to 14%' | translate }}
        </sb-badge>
      </div>
    </div>

    <!-- when the plan is enterprice the customer will not be able to change the plan -->
    @if (hasEnterprisePlan) {
      <sb-notice
        [variant]="'info'"
        [description]="
          'Do you want to change your plan? Contact sales, send an email to [email]'
            | translate
            | translationParams
              : {
                  email: 'enterprise@shiftbase.com' | mailto
                }
        "
      ></sb-notice>
      <sb-plan-card
        [name]="currentSubscription.plan.name"
        [displayPrice]="currentSubscription.display_price"
        [isCurrent]="true"
        [selected]="true"
        [display]="'horizontal'"
      ></sb-plan-card>
    } @else {
      <div class="grid grid-cols-1 gap-3 md:grid-cols-3 lg:gap-6">
        @for (subscription of subscriptions; track subscription.id) {
          <sb-plan-card
            [mostPopular]="planFeatures[subscription.plan.weight].mostPopular"
            [selected]="
              selectedSubscription
                ? selectedSubscription?.id === subscription.id
                : currentSubscription?.id === subscription.id
            "
            [isCurrent]="currentSubscription?.id === subscription.id"
            [inTrial]="inTrialPeriod"
            [isFree]="subscription.price === 0"
            [name]="subscription.plan.name"
            [tagline]="planFeatures[subscription.plan.weight].tagline | translate"
            [disabled]="determineDisabledStateForPlanCards(subscription)"
            [displayPrice]="getDisplayPrice(subscription)"
            [minOrderQuantity]="subscription.min_order_quantity"
            [featureTitle]="planFeatures[subscription.plan.weight].featureTitle | translate"
            [features]="planFeatures[subscription.plan.weight].features"
            [brandColor]="planFeatures[subscription.plan.weight].brandColor"
            (selectPlan)="selectSubscription($event, subscription)"
            [trialGranted]="trialGranted"
          >
            <ng-template #disabledTooltip>
              <div class="flex flex-col gap-2">
                <span>{{
                  'You cannot select the [subscription] subscription:'
                    | translate
                    | translationParams: { subscription: subscription.plan.name }
                }}</span>
                <ng-container *ngFor="let quota of subscription.quotaReached">
                  <span
                    >{{
                      quota.limit > 0
                        ? ('You have [usage] [name]. The [subscription] subscription includes a maximum of [limit] [name].'
                          | translate
                          | translationParams
                            : {
                                usage: quota.usage,
                                name: quota.name,
                                subscription: subscription.plan.name,
                                limit: quota.limit
                              })
                        : ('You have [usage] [name]. The [subscription] subscription includes no [name].'
                          | translate
                          | translationParams
                            : {
                                usage: quota.usage,
                                name: quota.name,
                                subscription: subscription.plan.name
                              })
                    }}
                  </span>
                </ng-container>
              </div>
            </ng-template>
          </sb-plan-card>
        }
      </div>
    }

    @if (hasEarlyAdopterPlan) {
      <sb-plan-card
        [name]="'Early Adopter' | translate"
        [displayPrice]="currentSubscription.display_price"
        [isCurrent]="true"
        [selected]="selectedSubscription.plan.weight === planTypeWeight[planType.EARLY_ADOPTER]"
        [display]="'horizontal'"
      ></sb-plan-card>
    }
  </div>
}

@if (subscriptions?.length) {
  <div
    class="my-6 flex items-center justify-between rounded-2xl border-2 border-solid border-grey-200 px-6 py-4"
    [ngClass]="{
      'opacity-50': selectedSubscription?.plan?.weight === planTypeWeight[planType.FREE]
    }"
  >
    <div class="flex items-center">
      <img class="mr-6 size-12" src="/assets/images/app-center-plus-logo.svg" />
      <div class="flex flex-col">
        <span class="mb-2 text-2xl font-semibold text-word-mark-800">
          {{ 'Add App Center Plus for ' | translate }}
          {{ integrationPlusPrice | subscriptionCurrency }}<span class="text-base">{{ '/month' | translate }}</span>
        </span>
        <span class="text-base font-medium text-word-mark-600">{{
          "With App Center Plus, in addition to our free integrations, you also get access to all 'Plus' integrations."
            | translate
        }}</span>
      </div>
    </div>
    <sb-slide-toggle
      [formInputCtrl]="integrationPlus"
      [sbTooltip]="integrationPlusTooltip"
      [sbTooltipDisabled]="!integrationPlus.disabled"
      [sbTooltipPlacement]="'top-left'"
    ></sb-slide-toggle>

    <ng-template #integrationPlusTooltip>
      @if (hasEnterprisePlan) {
        <span>{{ 'App Center Plus is included in your plan' | translate }}</span>
      } @else {
        <span>
          <span class="font-bold">{{ 'App Center Plus' | translate }}</span>
          {{ 'is only available within our' | translate }}
          <span class="font-bold">Basic</span>
          {{ 'and' | translate }}
          <span class="font-bold">Premium</span>
          {{ 'plans' | translate }}.
          {{ 'Upgrade now, and get access to all our integrations!' | translate }}
        </span>
      }
    </ng-template>
  </div>
}

<div class="mt-6 flex flex-col items-center gap-4">
  <a
    class="cursor-pointer border-0 border-b-2 border-solid border-primary-500 text-center text-base font-bold text-primary-500"
    [attr.href]="featuresUrl"
    target="_blank"
  >
    {{ 'Compare all features' | translate }}
  </a>
</div>
