import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChoosePlanCardComponent } from '@app/+authenticated/+account/billing/choose-plan/choose-plan-card/choose-plan-card.component';
import {
  EstimateItemModel,
  InvoicePeriodType,
  PlanType,
  SubscriptionEstimatesModel,
  SubscriptionModel,
} from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { Features } from '@app/enums';
import { BoldPipe } from '@app/pipes/bold.pipe';
import { DecimalPipe } from '@app/pipes/decimal.pipe';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { SharedPipeModule } from '@app/pipes/shared-pipe.module';
import { SubscriptionCurrencyPipe } from '@app/pipes/subscription-currency.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { getAccountState } from '@app/reducers/account/account.service';
import { VatResponseStatus } from '@app/shared/billing/billing.model';
import { SbFormModules } from '@app/shared/form-input/sb-form.modules';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BadgeComponent } from '@sb/ui';
import { ContentStateComponent } from '@shared/content-state/content-state.component';
import { StepsModule } from 'primeng/steps';
import { filter, Subscription, take } from 'rxjs';

import { VatChanges } from '../forms/billing-details/billing-details-form.component';
import { OrderSummaryRowComponent } from './order-summary-row/order-summary-row.component';

@Component({
  selector: 'order-summary',
  templateUrl: './order-summary.component.html',
  standalone: true,
  imports: [
    StepsModule,
    ChoosePlanCardComponent,
    CommonModule,
    TranslateModule,
    TranslationParamsPipe,
    BoldPipe,
    SharedPipeModule,
    ContentStateComponent,
    DecimalPipe,
    LocaleDatePipe,
    FeatureFlagPipe,
    SbFormModules,
    BadgeComponent,
    SubscriptionCurrencyPipe,
    OrderSummaryRowComponent,
  ],
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent implements OnInit, OnDestroy {
  public selectedSubscription: SubscriptionModel;
  public estimates: SubscriptionEstimatesModel;
  public estimatesError = false;
  private dataSubs = new Subscription();
  public features = Features;
  public hasIntegrationPlus: boolean;
  public integrationPlusPrice = 10;
  public vatPercentage = 0;
  public countryCode: string;
  public planItem: EstimateItemModel;
  public vatStatus: VatResponseStatus;
  public vatNumber: string;
  public _vatNote = '[countryCode] [vatPercentage]%';
  public vatNote = this._vatNote; // Default value

  @Output()
  public estimatesDone = new EventEmitter<boolean>();

  public hasTrial: boolean;

  @Input()
  public yearlyBillingToggle: FormControl<boolean>;
  @Input()
  public set vatInfo(changes: VatChanges) {
    if (changes) {
      this.vatStatus = changes.status;
      this.vatNote =
        changes.vatNumber && changes.status === VatResponseStatus.UNDETERMINED
          ? `${this._vatNote} ${this.translate.instant('(could not validate VAT number)')}`
          : this._vatNote;

      if (changes.vatNumber !== this.vatNumber) {
        this.vatNumber = changes.vatNumber;
        this.getEstimates();
      }
    }
  }
  @Input()
  public set newCountryCode(code: string) {
    if (code && code !== this.countryCode) {
      this.countryCode = code;
      this.getEstimates();
    }
  }
  @Input()
  public skipBilling: boolean;

  @Output()
  public estimatesFailed = new EventEmitter<HttpErrorResponse>();

  public invoicePeriod: InvoicePeriodType;

  public constructor(
    public subscriptionService: SubscriptionService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private featureService: FeatureService,
    private translate: TranslateService,
  ) {}

  public ngOnInit() {
    const selectedSubscription = this.subscriptionService.getSelectedSubscription().getValue();

    this.selectedSubscription = selectedSubscription?.subscription;
    this.hasIntegrationPlus = selectedSubscription?.integrationPlus;
    this.invoicePeriod = selectedSubscription?.invoicePeriod;

    this.yearlyBillingToggle.setValue(this.invoicePeriod === InvoicePeriodType.YEAR);

    this.dataSubs.add(
      this.store.select(getAccountState).subscribe((state) => {
        this.countryCode = state.account.country;
        this.hasTrial = state.account.hasTrial;

        if (this.selectedSubscription && this.skipBilling) {
          this.getEstimates();
        }
      }),
    );

    this.dataSubs.add(
      this.yearlyBillingToggle.valueChanges.subscribe((value) => {
        this.invoicePeriod = value ? InvoicePeriodType.YEAR : InvoicePeriodType.MONTH;
        this.getEstimates();
        this.subscriptionService.setSelectedSubscription({
          subscription: this.selectedSubscription,
          invoicePeriod: this.invoicePeriod,
          integrationPlus: this.hasIntegrationPlus,
        });
      }),
    );

    this.dataSubs.add(
      this.yearlyBillingToggle.valueChanges.subscribe((value) => {
        this.invoicePeriod = value ? InvoicePeriodType.YEAR : InvoicePeriodType.MONTH;
        this.getEstimates();
        this.subscriptionService.setSelectedSubscription({
          subscription: this.selectedSubscription,
          invoicePeriod: this.invoicePeriod,
          integrationPlus: this.hasIntegrationPlus,
        });
      }),
    );

    if (!selectedSubscription) {
      return;
    }

    if (this.selectedSubscription.plan.type === PlanType.FREE) {
      this.yearlyBillingToggle.disable();
    }

    this.dataSubs.add(
      this.store
        .select(getAccountState)
        .pipe(
          // Make sure account is loaded
          filter((state) => !!state?.account),
          // Only take one to initialize
          // and avoid overwriting newCountryCode
          take(1),
        )
        .subscribe((state) => {
          this.countryCode = state.account.country;
        }),
    );
  }

  private getEstimates() {
    if (!this.countryCode || this.vatStatus === VatResponseStatus.INVALID) {
      this.estimatesError = this.vatStatus === VatResponseStatus.INVALID;
      return;
    }

    // Clear estimates to trigger "loading" state
    this.estimates = null;
    this.estimatesError = false;

    this.dataSubs.add(
      this.subscriptionService
        .getEstimates({
          plan: this.selectedSubscription.plan.type,
          integrationPlus: this.hasIntegrationPlus,
          country: this.countryCode,
          vatNumber: this.vatNumber,
          invoicePeriod: this.invoicePeriod,
        })
        .subscribe({
          next: (estimate: SubscriptionEstimatesModel) => {
            this.estimates = estimate;

            // estimate.taxes[0].description example: 'VAT @ 21 %'
            const taxDescription = estimate?.taxes?.length ? estimate.taxes[0].description : null;
            // extract number after "@"
            if (taxDescription) {
              this.vatPercentage = parseInt(taxDescription.substring(taxDescription.indexOf('@') + 1));
            }

            if (this.estimates.line_items?.length) {
              this.planItem = this.estimates.line_items.find((item) => item.entity_type === 'plan_item_price');
              const integrationPlusItem = this.estimates.line_items.find(
                (item) => item.entity_type === 'addon_item_price',
              );

              if (integrationPlusItem) {
                this.integrationPlusPrice = integrationPlusItem.amount / 100;
              }
            }

            this.estimatesDone.emit(true);

            this.cd.detectChanges();
          },
          error: (error: HttpErrorResponse) => {
            this.estimatesError = true;
            this.estimatesFailed.emit(error);
            this.cd.detectChanges();
          },
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
