<div class="flex gap-8">
  <billing-details-form
    class="flex-1"
    [hideButtonBar]="true"
    [noPadding]="true"
    (saved)="billingSaved.emit()"
    (vatStatusChange)="vatChanged($event)"
    (countryCodeChange)="countryChanged($event)"
  ></billing-details-form>
  <order-summary
    class="flex-1"
    [yearlyBillingToggle]="yearlyBillingToggle"
    [vatInfo]="vatInfo"
    [newCountryCode]="countryCode"
    (estimatesFailed)="billingDetailsForm.handleError($event)"
    (estimatesDone)="estimatesDone.emit($event)"
  ></order-summary>
</div>
