import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PlanWizardService } from '@app/+authenticated/+account/billing/plan-wizard/plan-wizard.service';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { TranslateModule } from '@ngx-translate/core';
import { BillingService } from '@shared/billing/billing.service';
import { ContentStateComponent } from '@shared/content-state/content-state.component';
import { of, Subscription, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'order-completion',
  templateUrl: './order-completion.component.html',
  standalone: true,
  imports: [TranslateModule, NgIf, ContentStateComponent],
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCompletionComponent implements OnInit, OnDestroy {
  private dataSubs = new Subscription();

  public validPaymentMethod: boolean;
  public isLoading = true;

  @Output()
  public closePressed = new EventEmitter();

  @Output()
  public backPressed = new EventEmitter();

  public constructor(
    private billingService: BillingService,
    private planWizard: PlanWizardService,
    private cd: ChangeDetectorRef,
    private subscriptionService: SubscriptionService,
  ) {}

  public ngOnInit() {
    const selectedSubscription = this.subscriptionService.getSelectedSubscription().value;

    if (!selectedSubscription?.subscription) {
      return;
    }
    this.dataSubs.add(
      this.billingService
        .getChargeBeePaymentMethod()
        .pipe(
          filter((paymentMethod) => !!paymentMethod),
          tap((paymentMethod) => {
            this.validPaymentMethod = paymentMethod?.status === 'valid';
          }),
          switchMap(() =>
            !this.validPaymentMethod
              ? of(null)
              : this.subscriptionService.saveSubscription(
                  selectedSubscription.subscription.id,
                  selectedSubscription.integrationPlus,
                  selectedSubscription.invoicePeriod,
                ),
          ),
        )
        .subscribe({
          next: () => {
            this.isLoading = false;
            if (this.validPaymentMethod) {
              localStorage.setItem('planWizardLanding', 'completed');
            }
            this.planWizard.paymentReady$.next(true);
            this.planWizard.paymentValid$.next(this.validPaymentMethod);
            this.cd.detectChanges();
          },
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }
}
