import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BillingDetailsFormComponent, VatChanges } from '../../forms/billing-details/billing-details-form.component';
import { OrderSummaryComponent } from '../../order-summary/order-summary.component';

@Component({
  selector: 'billing-and-summary',
  standalone: true,
  imports: [CommonModule, BillingDetailsFormComponent, OrderSummaryComponent, TranslateModule],
  templateUrl: './billing-and-summary.component.html',
})
export class BillingAndSummaryComponent {
  @Input()
  public currentStepIndex: number;
  @Input()
  public yearlyBillingToggle: FormControl<boolean>;
  @Output()
  public billingSaved = new EventEmitter<void>();
  @ViewChild(BillingDetailsFormComponent)
  public billingDetailsForm: BillingDetailsFormComponent;
  @Output()
  public estimatesDone = new EventEmitter<boolean>();

  public vatInfo: VatChanges;
  public vatNumber: string;
  public countryCode: string;

  public vatChanged(changes: VatChanges) {
    this.vatInfo = changes;
  }

  public countryChanged(countryCode: string) {
    this.countryCode = countryCode;
  }
}
