<app-confirm-downgrade
  *ngIf="showConfirmDowngrade"
  [selectedSubscription]="selectedSubscription?.subscription"
  [currentSubscription]="currentSubscription"
  [nextBillingDate]="nextBillingDate"
  (cancel)="cancelDowngrade()"
  (confirm)="confirmDowngrade()"
  [isLoading]="isLoading"
></app-confirm-downgrade>

<div class="p-3" [ngClass]="{ hidden: showConfirmDowngrade }">
  <p-steps
    *ngIf="currentStepIndex !== steps.length"
    class="block px-4"
    [model]="steps"
    [activeIndex]="currentStepIndex"
    (activeIndexChange)="stepChanged($event)"
    [readonly]="true"
  ></p-steps>

  <ng-container [ngSwitch]="currentStepIndex">
    <choose-plan-card
      *ngSwitchCase="planWizardStep.CHOOSE_PLAN"
      class="block px-4 lg:px-12"
      [forceUpgrade]="forceUpgrade"
      [yearlyBillingToggle]="yearlyBillingToggle"
      [trialExpired]="trialExpired"
    ></choose-plan-card>
    <billing-details-form
      *ngSwitchCase="planWizardStep.BILLING_DETAILS"
      class="p-4"
      [backButtonEnabled]="true"
      [saveLabel]="'Next' | translate"
      (saved)="stepChanged(currentStepIndex + 1)"
      (backPressed)="previousStep()"
    ></billing-details-form>
    <order-summary
      *ngSwitchCase="planWizardStep.ORDER_SUMMARY"
      class="p-4"
      [yearlyBillingToggle]="yearlyBillingToggle"
      [skipBilling]="skipBilling"
    ></order-summary>
    <billing-and-summary
      *ngSwitchCase="planWizardStep.BILLING_AND_SUMMARY"
      class="block p-4"
      [yearlyBillingToggle]="yearlyBillingToggle"
      [currentStepIndex]="currentStepIndex"
      (billingSaved)="nextStep()"
      (estimatesDone)="estimatesDone = $event"
    >
    </billing-and-summary>
    <order-completion
      *ngSwitchCase="planWizardStep.ORDER_COMPLETE"
      (closePressed)="exitWiz()"
      (backPressed)="previousStep()"
      class="mx-3.5 mt-4"
    ></order-completion>
  </ng-container>
</div>

<div
  *ngIf="
    currentStepIndex !== planWizardStep.ORDER_COMPLETE &&
    currentStepIndex !== planWizardStep.BILLING_DETAILS &&
    !showConfirmDowngrade
  "
  class="flex justify-end gap-2 p-4"
>
  <button
    *ngIf="currentStepIndex !== planWizardStep.CHOOSE_PLAN"
    sb-button
    [color]="'secondary'"
    (click)="previousStep()"
  >
    {{ 'Back' | translate }}
  </button>
  <button
    *ngIf="currentStepIndex === planWizardStep.CHOOSE_PLAN"
    sb-button
    [color]="'primary'"
    [disabled]="!hasSelectedSubscription"
    (click)="nextStep()"
  >
    {{ nextBtnLabel }}
  </button>

  <button
    *ngIf="currentStepIndex === planWizardStep.BILLING_AND_SUMMARY"
    sb-button
    [color]="'primary'"
    type="submit"
    [disabled]="billingAndSummary?.billingDetailsForm?.billingDetailsForm.status === 'PENDING' || !estimatesDone"
    (click)="submitBillingForm()"
    [sbLadda]="isLoading"
  >
    {{ 'Continue to payment' | translate }}
  </button>

  <button
    *ngIf="currentStepIndex === planWizardStep.ORDER_SUMMARY && skipBilling"
    sb-button
    [color]="'primary'"
    (click)="confirmPlanChange()"
    [sbLadda]="isSaving"
  >
    {{ 'Confirm change' | translate }}
  </button>
</div>
