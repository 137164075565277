<content-state [isLoading]="isLoading">
  <div class="flex flex-col">
    <ng-container *ngIf="validPaymentMethod">
      <span class="font-semibold">{{ 'Congratulations' | translate }}</span>
      <span class="mt-1">{{
        'You’ve successfully set up your billing and payment details. You can close this window.' | translate
      }}</span>
    </ng-container>
    <ng-container *ngIf="!validPaymentMethod">
      <span class="font-semibold">{{ 'Error' | translate }}</span>
      <span class="mt-1">{{
        'Something went wrong while setting up your billing and payment details. You can go back and try again.'
          | translate
      }}</span>
    </ng-container>
  </div>

  <div class="flex mt-4 flex-row-reverse">
    <button (click)="closePressed.emit()" class="btn btn-primary ml-2 flex items-center">
      {{ 'Close' | translate }}
    </button>
    <button *ngIf="!validPaymentMethod" (click)="backPressed.emit()" class="btn btn-info flex items-center">
      {{ 'Back' | translate }}
    </button>
  </div>
</content-state>
