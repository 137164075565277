import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SubscriptionCurrencyPipe } from '@app/pipes/subscription-currency.pipe';

interface ItemModel {
  description: string | SafeHtml;
  amount: number;
  note?: string | SafeHtml;
}

@Component({
  selector: 'order-summary-row',
  standalone: true,
  imports: [CommonModule, SubscriptionCurrencyPipe],
  templateUrl: './order-summary-row.component.html',
})
export class OrderSummaryRowComponent {
  @Input()
  public item: ItemModel;

  @Input()
  public extraItem?: ItemModel;
}
