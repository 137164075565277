import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  InvoicePeriodType,
  PlanQuotaModel,
  QuotaType,
  SubscriptionModel,
} from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { SubscriptionService } from '@app/+authenticated/+reports/shared/subscriptions/subscription.service';
import { BoldPipe } from '@app/pipes/bold.pipe';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { LaddaDirective } from '@app/shared/ladda/ladda.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@sb/ui';

const quotaTypesToFilterOut = [
  QuotaType.SEATS,
  QuotaType.ACTIVE_TIME_OFF_BALANCES,
  QuotaType.RATE_CARDS_WITH_MULTIPLE_TIME_BLOCKS,
  QuotaType.RATE_CARDS_WITH_TIME_SURCHARGES,
  QuotaType.ABSENCE_POLICIES,
  QuotaType.HOLIDAY_GROUPS,
];

@Component({
  selector: 'app-confirm-downgrade',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    IconComponent,
    TranslationParamsPipe,
    BoldPipe,
    LocaleDatePipe,
    LaddaDirective,
  ],
  templateUrl: './confirm-downgrade.component.html',
})
export class ConfirmDowngradeComponent implements OnInit {
  @Input()
  public selectedSubscription: SubscriptionModel;
  @Input()
  public nextBillingDate: Date;
  @Input()
  public isLoading: boolean;
  @Input()
  public currentSubscription: SubscriptionModel;

  @Output()
  public cancel = new EventEmitter<void>();
  @Output()
  public confirm = new EventEmitter<void>();

  public confirmDonwngradeSubtitleMessage: string;

  public constructor(
    private translate: TranslateService,
    private subscriptionService: SubscriptionService,
  ) {}

  public ngOnInit(): void {
    this.generateConfirmDonwngradeSubtitleMessage();
  }

  public generateConfirmDonwngradeSubtitleMessage(): void {
    if (this.isChangingBillingCycleToMonthly && this.isDeactivatingIntegrationPlus) {
      if (this.selectedSubscription?.plan.type === this.currentSubscription.plan.type) {
        this.confirmDonwngradeSubtitleMessage = this.translate.instant(
          `Downgrade Plan to {{currentPlanName}} without App Center Plus (Monthly)`,
          {
            currentPlanName: this.currentSubscription.plan.name,
          },
        );
        return;
      }

      this.confirmDonwngradeSubtitleMessage = this.translate.instant(
        `{{targetPlanName}} without App Center Plus (Monthly)`,
        { targetPlanName: this.selectedSubscription.plan.name },
      );
      return;
    }

    if (this.isChangingBillingCycleToMonthly) {
      if (this.selectedSubscription?.plan.type === this.currentSubscription.plan.type) {
        this.confirmDonwngradeSubtitleMessage = this.translate.instant(`{{currentPlanName}} (Monthly)`, {
          currentPlanName: this.currentSubscription.plan.name,
        });
        return;
      }

      this.confirmDonwngradeSubtitleMessage = this.translate.instant(`{{targetPlanName}} (Monthly)`, {
        targetPlanName: this.selectedSubscription.plan.name,
      });
      return;
    }

    if (this.isDeactivatingIntegrationPlus) {
      if (this.selectedSubscription?.plan.type === this.currentSubscription.plan.type) {
        this.confirmDonwngradeSubtitleMessage = this.translate.instant(`{{currentPlanName}} without App Center Plus`, {
          currentPlanName: this.currentSubscription.plan.name,
        });
        return;
      }

      this.confirmDonwngradeSubtitleMessage = this.translate.instant(`{{targetPlanName}} without App Center Plus`, {
        targetPlanName: this.selectedSubscription.plan.name,
      });
      return;
    }

    this.confirmDonwngradeSubtitleMessage = this.selectedSubscriptionName;
  }

  public get isChangingBillingCycleToMonthly(): boolean {
    return (
      this.subscriptionService.selectedSubscriptionValue?.invoicePeriod === InvoicePeriodType.MONTH &&
      this.currentSubscription.invoice_period !== InvoicePeriodType.MONTH
    );
  }

  public get isDeactivatingIntegrationPlus(): boolean {
    return !this.subscriptionService.selectedSubscriptionValue?.integrationPlus;
  }

  public get selectedSubscriptionName(): string {
    return this.selectedSubscription?.plan?.name;
  }

  public get quotaReached(): PlanQuotaModel[] {
    return this.selectedSubscription?.quotaReached?.filter((quota) => !quotaTypesToFilterOut.includes(quota.type));
  }

  public get overQuota(): boolean {
    return this.quotaReached?.length > 0;
  }
}
