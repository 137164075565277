<content-state
  [icon]="''"
  [isLoading]="!estimates && !estimatesError && countryCode"
  [isEmpty]="!estimates && estimatesError"
>
  <ng-container *ngIf="estimates">
    <div class="rounded-lg border border-solid border-grey-200">
      <div class="flex justify-between px-4 py-2 text-base font-semibold text-word-mark-800">
        <div class="flex flex-col">
          <span>{{ selectedSubscription?.plan?.name }} plan</span>
          <span class="text-sm font-medium text-word-mark-600">
            {{
              yearlyBillingToggle.value
                ? ('per active employee/month, billed yearly' | translate)
                : ('per active employee/month, billed monthly' | translate)
            }}
          </span>
        </div>
        <span *ngIf="!yearlyBillingToggle.value; else yearlyBillingPlanItem">
          {{ planItem?.unit_amount / 100 | subscriptionCurrency }}
        </span>

        <ng-template #yearlyBillingPlanItem>
          <span>{{ planItem?.unit_amount / 100 / 12 | subscriptionCurrency }}</span>
        </ng-template>
      </div>
      <div class="flex items-center gap-2 bg-grey-100 px-4 py-2">
        <sb-slide-toggle [formInputCtrl]="yearlyBillingToggle"> </sb-slide-toggle>
        <sb-badge [color]="'blue'" [ngClass]="{ 'opacity-50': yearlyBillingToggle.disabled }">
          {{ 'Save up to 14%' | translate }}
        </sb-badge>
        <span class="text-sm font-semibold" [ngClass]="{ 'opacity-50': yearlyBillingToggle.disabled }">
          {{ 'with yearly billing' | translate }}
        </span>
      </div>
    </div>

    <div class="my-8 flex flex-col gap-4">
      <!-- Plan -->

      <order-summary-row
        *ngIf="!yearlyBillingToggle.value; else yearlyBillingPlanRow"
        [item]="{
          description: planItem?.description,
          amount: planItem?.amount / 100,
          note:
            '[price] x [employees] employees x 1 month'
            | translate
            | translationParams
              : {
                  price: planItem?.unit_amount / 100 | subscriptionCurrency,
                  employees: planItem?.quantity ? planItem?.quantity : 0
                }
        }"
        [extraItem]="
          planItem?.discount_amount
            ? {
                description: 'Discount' | translate,
                amount: (planItem?.discount_amount * -1) / 100
              }
            : null
        "
      >
      </order-summary-row>
      <ng-template #yearlyBillingPlanRow>
        <order-summary-row
          [item]="{
            description: planItem?.description,
            amount: planItem?.amount / 100,
            note:
              '[price] x [employees] employees x 12 months'
              | translate
              | translationParams
                : { price: planItem?.unit_amount / 100 / 12 | subscriptionCurrency, employees: planItem?.quantity }
          }"
          [extraItem]="
            planItem?.discount_amount
              ? {
                  description: 'Discount' | translate,
                  amount: (planItem?.discount_amount * -1) / 100
                }
              : null
          "
        >
        </order-summary-row>
      </ng-template>
      <ng-container *ngIf="hasIntegrationPlus">
        <order-summary-row
          *ngIf="!yearlyBillingToggle.value; else yearlyBillingIntegrationRow"
          [item]="{
            description: 'App Center Plus' | translate,
            amount: integrationPlusPrice,
            note:
              '[price] x 1 month'
              | translate
              | translationParams: { price: integrationPlusPrice | subscriptionCurrency }
          }"
        >
        </order-summary-row>
        <ng-template #yearlyBillingIntegrationRow>
          <order-summary-row
            [item]="{
              description: 'App Center Plus' | translate,
              amount: integrationPlusPrice,
              note:
                '[price] x 12 months'
                | translate
                | translationParams: { price: integrationPlusPrice / 12 | subscriptionCurrency }
            }"
          >
          </order-summary-row>
        </ng-template>
      </ng-container>

      <hr class="m-0 border border-solid border-grey-200" />

      <!-- Subtotal -->
      <order-summary-row
        [item]="{
          description: 'Subtotal' | translate,
          amount: estimates.sub_total
        }"
      >
      </order-summary-row>

      <!-- VAT -->
      <order-summary-row
        [item]="{
          description: 'VAT' | translate,
          amount: estimates.tax_amount,
          note: vatNote | translate | translationParams: { countryCode, vatPercentage }
        }"
      >
      </order-summary-row>

      <!-- Total -->
      <order-summary-row
        *ngIf="!yearlyBillingToggle.value; else yearlyBillingRow"
        [item]="{
          description:
            'Total due on [date]'
            | translate
            | translationParams
              : {
                  date: estimates.next_billing_date | localeDate: { year: 'numeric', month: 'long', day: 'numeric' }
                },
          amount: estimates.total
        }"
      >
      </order-summary-row>
      <ng-template #yearlyBillingRow>
        <order-summary-row
          [item]="{
            description: 'Total per year' | translate,
            note:
              'Due on [date], then every year'
              | translate
              | translationParams
                : {
                    date: estimates.next_billing_date | localeDate: { year: 'numeric', month: 'long', day: 'numeric' }
                  },
            amount: estimates.total
          }"
        >
        </order-summary-row>
      </ng-template>
    </div>
  </ng-container>
</content-state>
