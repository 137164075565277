import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { PlanType, PlanTypeWeight } from '../../../../+reports/shared/subscriptions/subscription.model';

export const planFeatures: {
  [key: number]: {
    subTitle: string;
    tagline?: string;
    brandColor?: 'primary' | 'green';
    mostPopular: boolean;
    featureTitle?: string;
    features?: string[];
    baseFeatures: string[];
    oldBaseFeatures: string[];
  };
} = {
  [PlanTypeWeight[PlanType.EARLY_ADOPTER]]: {
    subTitle: _('Free online schedule'),
    baseFeatures: [_('Basic integrations'), _('Plus-minus hour surcharges')],
    oldBaseFeatures: [_('Basic integrations'), _('Plus-minus hour surcharges')],
    mostPopular: false,
  },
  [PlanTypeWeight[PlanType.FREE]]: {
    subTitle: _('Free online schedule'),
    tagline: _('A first introduction to digital scheduling and time tracking'),
    brandColor: 'primary',
    featureTitle: _('Free includes'),
    features: [
      _('Up to 75 employees'),
      _('Up to 5 teams'),
      _('1 department'),
      _('Manual time tracking'),
      _('Basic scheduling'),
    ],
    baseFeatures: [_('Max. 75 employees'), _('Max. 1 department'), _('Max. 5 teams')],
    oldBaseFeatures: [_('Max. 75 employees'), _('Max. 1 department'), _('Max. 5 teams')],
    mostPopular: false,
  },
  [PlanTypeWeight[PlanType.PREMIUM]]: {
    subTitle: _('Most complete solution'),
    tagline: _('Full control over personnel costs by controlling forecasts and budgets'),
    brandColor: 'green',
    featureTitle: _('Everything in Basic, plus'),
    features: [
      _('Unlimited employee'),
      _('Unlimited teams'),
      _('Unlimited departments'),
      _('Advanced time tracking'),
      _('Advanced absence management'),
      _('Forecasting'),
      _('Budget'),
      _('Surcharges'),
      _('Skill management'),
      _('Required shifts'),
      _('Flexpools'),
    ],
    baseFeatures: [
      _('All Basic features, plus'),
      _('Unlimited departments'),
      _('Publishing open shifts'),
      _('Unlimited teams & forecasting'),
      _('Advanced time registration'),
      _('Advanced reporting'),
    ],
    oldBaseFeatures: [
      _('All Starter features, plus'),
      _('Unlimited departments'),
      _('Publishing open shifts'),
      _('Unlimited teams & forecasting'),
      _('Advanced time registration'),
      _('Advanced reporting'),
    ],
    mostPopular: false,
  },
  [PlanTypeWeight[PlanType.BASIC]]: {
    subTitle: _('Perfect for small businesses'),
    tagline: _('Everything for professional employee scheduling and time tracking'),
    brandColor: 'primary',
    featureTitle: _('Everything in Free, plus'),
    features: [
      _('Unlimited employees'),
      _('Unlimited teams'),
      _('Up to 5 departments'),
      _('Basic time tracking'),
      _('Basic absence management'),
      _('Reports'),
      _('Availability'),
      _('Shift exchange'),
      _('Plus minus hours'),
      _('Open shifts'),
      _('Weather forecast'),
    ],
    baseFeatures: [
      _('All Free features, plus'),
      _('Unlimited teams & employees'),
      _('Max 5. departments'),
      _('Basic time registration'),
    ],
    oldBaseFeatures: [
      _('All Free features, plus'),
      _('Unlimited teams & employees'),
      _('Max 5. departments'),
      _('Basic time registration'),
    ],
    mostPopular: true,
  },
};

export const allFeaturesUrls = {
  nl: 'https://www.shiftbase.com/nl/tarieven#price-table',
  'nl-be': 'https://www.shiftbase.com/nl-be/tarieven#price-table',
  en: 'https://www.shiftbase.com/pricing#price-table',
  'en-gb': 'https://www.shiftbase.com/en-gb/pricing#price-table',
  de: 'https://www.shiftbase.com/de/preise#price-table',
  fr: 'https://www.shiftbase.com/fr/prix#price-table',
  es: 'https://www.shiftbase.com/es/precios#price-table',
  pl: 'https://www.shiftbase.com/pl/ceny#price-table',
  sv: 'https://www.shiftbase.com/sv/priser#price-table',
};

export const subscriptionChangeDialogConfig = {
  width: '100vw',
  height: '100vh',
  dismissableMask: true,
  closeOnEscape: true,
  closable: true,
  showHeader: false,
  styleClass: 'dialog-transparent',
  style: {
    position: 'fixed',
    top: 0,
    paddingTop: '50px',
  },
};
