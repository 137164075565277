<div class="flex flex-col text-base font-semibold text-word-mark-800">
  <div class="flex justify-between">
    <span>{{ item.description }}</span>
    <span>{{ item.amount | subscriptionCurrency }}</span>
  </div>
  <div class="text-sm font-medium text-word-mark-600">
    {{ item.note }}
  </div>
  <div class="flex items-center justify-between" *ngIf="extraItem">
    <span class="text-sm font-medium text-word-mark-600">
      {{ extraItem.description }}
    </span>
    <span>
      {{ extraItem.amount | subscriptionCurrency }}
    </span>
  </div>
</div>
